import { h } from 'preact'
import cn from 'classnames'

import { useTranslate } from '../hooks/translate.js'
import Dropdown from './dropdown.jsx'

import s from './language_selector.module.scss'

const LanguageSelector = ({ className, current, locales }) => {
  const translate = useTranslate()

  return (
    <Dropdown className={cn(s.base, className)}>
      <Dropdown.Toggle className={cn(s.toggle, s[current.languageName.toLowerCase()])} />

      <Dropdown.Menu className={s.menu}>
        {locales &&
          locales.map((locale) => (
            <a
              className={cn(s[locale.languageName.toLowerCase()], current.id === locale.id && s.current)}
              href={'http://' + locale.hostname}
            >
              {translate(locale.languageName)}
            </a>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageSelector
