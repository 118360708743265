import { h } from 'preact'

import NotFoundPage from './not_found_page.jsx'
import errorIcon from '../images/error_icon.svg'
import s from './error_page.module.scss'

const ErrorPage = ({ error }) =>
  error && error.status === 404 ? (
    <NotFoundPage />
  ) : (
    <section className={s.base}>
      <div className={s.errorOther}>
        <img src={errorIcon} />
        <div className={s.message}>
          <div>Oj då!</div>
          <div>Nu har tyvärr något blivit fel på vår sida. Pröva gärna igen och kontakta oss om felet kvarstår.</div>
        </div>
      </div>
    </section>
  )

export default ErrorPage
