import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { useLocation } from 'preact-iso'
import cn from 'classnames'

import { get, pick } from 'lowline'

import isLoading from '../../shared/utils/is_loading.js'
import { fetchCars, unloadCars } from '../store/cars_actions.js'
import { fetchSearch } from '../store/search_actions.js'
import scrollIntoView from '../util/scroll_into_view.js'
import Head from './head.jsx'

import ContactForm from './contact_form.jsx'
import Pagination from './pagination.jsx'
import Car from './car.jsx'
import CarSearchForm from './car_search_form.jsx'

import s from './bilar_page.module.scss'

const BilarPage = ({ route }) => {
  const { path, url } = useLocation()
  const dispatch = useDispatch()
  const { cars, pagination, search } = useSelector(['cars', 'pagination', 'search'])

  if (!cars && !search) {
    throw Promise.all([dispatch(fetchCars(url.split('?')[1] || '')), dispatch(fetchSearch())])
  }
  const topPagination = useRef()
  const hasRenderedRef = useRef(false)

  // useIsomorphicEffect(() => {
  //   // TODO removing this conditional causes the SSR to get caught in loop
  //   if (import.meta.env.SSR && !cars) {
  //     dispatch(fetchCars(location.search))
  //   }
  //   // throw Promise.all([
  //   //   dispatch(fetchCars(location.search)),
  //   //   dispatch(fetchSearch())
  //   // ])

  //   if (!search) {
  //     // const done = loading()

  //     // dispatch(fetchSearch()).then(done)
  //     dispatch(fetchSearch())
  //   }

  //   return () => dispatch(unloadCars())
  // }, [])

  useEffect(() => () => unloadCars, [])

  useEffect(() => {
    if (route.path !== path) return

    if (!hasRenderedRef.current) {
      hasRenderedRef.current = true

      return
    }

    isLoading()

    dispatch(fetchCars(url.split('?')[1] || ''))
      .then(() => isLoading(false))
      .then(() => {
        if (get(window.history, 'state.scrollTop') == null) {
          scrollIntoView(topPagination.current)
        }
      })
  }, [url])

  if (!cars) {
    return null
  }

  return (
    <section className={s.base}>
      <Head>
        <title> : Bilar</title>
      </Head>

      <h1>Bilar</h1>

      <div className={cn(s.search)}>
        <div className={cn(s.searchFormContainer)}>
          <CarSearchForm location={location} search={search} />
        </div>
      </div>

      <Pagination
        className={cn(s.pagination, s.top)}
        {...pagination}
        {...pick(location, 'pathname', 'search')}
        forwardRef={topPagination}
      />

      <div className={cn(s.cars)}>
        {cars.length ? (
          cars.map((car) => <Car key={car.id} basePath={location.pathname} className={s.car} {...car} />)
        ) : (
          <div className={s.noMatchingCars}>Inga matchande bilar hittades</div>
        )}
      </div>

      <Pagination className={cn(s.pagination, s.bottom)} {...pagination} {...pick(location, 'pathname', 'search')} />

      <div className={s.contactFormWrapper}>
        <section className={s.contactForm}>
          <h1>Behöver du hjälp med att välja?</h1>
          <hr />
          <p>
            Våra säljare hjälper dig att välja bilen som passar just dig, fyll i kontaktformuläret så hör vi av oss så
            fort vi kan!
          </p>
          <ContactForm className={s.form} />
        </section>
      </div>
    </section>
  )
}

export default BilarPage
