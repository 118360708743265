import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import cn from 'classnames'
import { useLocation } from 'preact-iso'

import { useTranslate } from '../hooks/translate.js'
import s from './navigation.module.scss'

const Navigation = ({ className, locale, routes }) => {
  const { path: currentPath } = useLocation()
  const translate = useTranslate()
  const [style, setStyle] = useState(null)
  const [visible, setVisible] = useState(false)

  const positionUnderline = useCallback((target = document.querySelector('.' + s.current)) => {
    setStyle({
      left: target ? target.offsetLeft + 'px' : '100%',
      width: target ? target.clientWidth + 'px' : '0',
    })
  }, [])
  const onPointerEnter = useCallback((e) => positionUnderline(e.target), [])
  const onPointerLeave = useCallback(() => positionUnderline(), [])

  useEffect(() => {
    positionUnderline()
    toggle(false)
  }, [currentPath])

  const toggle = useCallback((force) => {
    setVisible((visible) => {
      const next = typeof force === 'boolean' ? force : !visible
      document.body.classList.toggle('shift', next)
      return next
    })
  }, [])

  return (
    <nav className={cn(s.base, visible && s.visible, className)}>
      <button onClick={toggle} className={cn(s.toggleButton, visible && s.visible)}>
        <div className={s.barWrapper}>
          <div className={s.bar} />
          <div className={s.bar} />
          <div className={s.bar} />
        </div>
      </button>

      <ul>
        {routes
          .filter(({ navigation }) => navigation !== false)
          .map((route) => {
            const routePath = (route.locales.find((routeLocale) => routeLocale.id === locale.id) || route).path
            return (
              <li
                className={cn(
                  s.link,
                  s[route.name],
                  (routePath === currentPath || (routePath !== '/' && currentPath.startsWith(routePath))) && s.current,
                )}
              >
                <a key={routePath} href={routePath} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
                  {translate(route.title)}
                </a>
              </li>
            )
          })}
      </ul>

      <div className={s.underline} style={style} />
    </nav>
  )
}

export default Navigation
