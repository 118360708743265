import './styles/main.scss'

import rek from 'rek'
import { h } from 'preact'
import { hydrate } from 'preact-iso'
import { Provider } from '@bmp/store/preact'
import Layout from './components/layout.jsx'
import createStore from './store/index.js'

const preloadedState = typeof __STATE__ !== 'undefined' ? __STATE__ : undefined

if (typeof history !== 'undefined') {
  history.scrollRestoration = 'manual'
}

// eslint-disable-next-line no-undef
const store = createStore(preloadedState, { extraArg: rek }, process.env.NODE_ENV === 'development')

hydrate(h(Provider, { store }, h(Layout)), document.body)
