const priceFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const formatPrice = (price) => priceFormatter.format(price)

const numberFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const formatNumber = (nbr) => numberFormatter.format(nbr)
