import { h } from 'preact'
import { useCallback, useState } from 'preact/hooks'
import cn from 'classnames'
import s from './range.module.scss'

const Range = ({ className, color, defaultValue, label, min, max, name, onInput, step }) => {
  const [value, setValue] = useState(defaultValue)

  const onLocalInput = useCallback((e) => {
    const value = e.currentTarget.value

    setValue(value)

    onInput?.(value, name)
  })

  return (
    <div className={cn(s.base, color && s[color], className)}>
      {label && <label>{label}</label>}

      <div className={s.track}>
        <input
          type='range'
          name={name}
          className={s.element}
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
          onInput={onLocalInput}
          style={{ '--progress': ((value - min) / (max - min)) * 100 + '%' }}
        />

        <div
          className={s.progress}
          style={{
            right: ((max - value) / (max - min)) * 100 + '%',
          }}
        />
      </div>
    </div>
  )
}

export default Range
