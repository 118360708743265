import { h } from 'preact'
import { useCallback, useEffect } from 'preact/hooks'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { get } from 'lowline'

import useIsomorphicEffect from '../util/use_isomorphic_effect.js'
import { fetchOpeningHours } from '../store/opening_hours_actions.js'
import { fetchOrganization } from '../store/organization_actions.js'
import ErrorPage from './error_page.jsx'
import NotFoundPage from './not_found_page.jsx'
import Footer from './footer.jsx'
import Header from './header.jsx'
// import Route from './route.jsx'
import Head from './head.jsx'
import { LocationProvider, Route, Router } from 'preact-iso'
import s from './layout.module.scss'

import throttle from '../util/throttle.js'

const remember = throttle(function remember() {
  window.history.replaceState(
    {
      ...window.history.state,
      scrollTop: window.scrollY,
    },
    '',
    null,
  )
}, 100)

const Components = import.meta.glob('./*_page.jsx', { eager: true })

const Layout = () => {
  const dispatch = useDispatch()
  const { error, locale, locales, openingHours, routes, organization } = useSelector([
    'error',
    'locale',
    'locales',
    'openingHours',
    'routes',
    'organization',
  ])

  // if (!organization && !openingHours) {
  //   throw Promise.all([
  //   dispatch(fetchOrganization()),
  //   dispatch(fetchOpeningHours())
  //   ])
  // }

  useIsomorphicEffect(() => {
    if (!organization) dispatch(fetchOrganization())
    if (!openingHours) dispatch(fetchOpeningHours())
  }, [])

  useEffect(() => {
    addEventListener('scroll', remember)

    return () => removeEventListener('scroll', remember)
  })

  const onRouteChange = useCallback(() => {
    const offset = get(window.history, 'state.scrollTop')

    window.scrollTo(0, offset || 0)
  }, [])

  return (
    <LocationProvider>
      <div id='app' className={s.base}>
        <Head>
          <title>{organization && organization.name}</title>
        </Head>

        <Header
          locales={locales}
          locale={locale}
          openingHours={openingHours}
          organization={organization || {}}
          routes={routes}
        />

        <main className={s.main}>
          {error && error.status !== 404 ? (
            <ErrorPage error={error} />
          ) : (
            <Router onRouteChange={onRouteChange}>
              {routes?.map((route) => (
                <Route
                  key={route.path}
                  route={route}
                  locale={locale}
                  path={
                    (
                      (route.locales.length > 0 && route.locales.find((routeLocale) => routeLocale.id === locale.id)) ||
                      route
                    ).path
                  }
                  component={Components['./' + route.component].default}
                />
              ))}
              <NotFoundPage default />
            </Router>
          )}
        </main>

        <Footer openingHours={openingHours} organization={organization} routes={routes} />
      </div>
    </LocationProvider>
  )
}

export default Layout
