export const fetchAll =
  (limit = 4) =>
  (_getState, _dispatch, rek) =>
    Promise.all([rek('/api/reco'), rek(`/api/reco/reviews?offset=0&limit=${limit}`)]).then(([ratings, reviews]) => ({
      reco: {
        ...ratings,
        reviews,
      },
    }))

export const fetchReviews =
  (limit = 4) =>
  (getState, _dispatch, rek) => {
    const { reco } = getState()

    const offset = (reco && reco.reviews && reco.reviews.length) || 0

    return rek(`/api/reco/reviews?offset=${offset}&limit=${limit}`).then((reviews) => ({
      reco: reco
        ? {
            ...reco,
            reviews: reco.reviews.concat(reviews),
          }
        : { reviews },
    }))
  }

export const fetchRating = () => (getState, _dispatch, rek) => {
  const { reco } = getState()

  return rek('/api/reco').then((result) => ({
    reco: reco
      ? {
          ...reco,
          ...result,
        }
      : result,
  }))
}
