import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import cn from 'classnames'
import disableScroll from '../../shared/utils/disable_scroll.js'

import s from './modal.module.scss'

const Modal = ({ children, className, fullscreen, noPadding, onClose, open }) => {
  const dialogRef = useRef(null)

  useEffect(() => {
    if (open) {
      disableScroll.disable()
      dialogRef.current.showModal()
    } else {
      disableScroll.enable()
      dialogRef.current.close()
    }
  }, [open])

  useEffect(() => {
    function onClick(event) {
      if (event.target === dialogRef.current) {
        onClose()
      }
    }

    dialogRef.current.addEventListener('click', onClick)

    return () => {
      disableScroll.enable()
      dialogRef.current.removeEventListener('click', onClick)
    }
  }, [])

  return (
    <dialog
      className={cn(s.base, fullscreen && s.fullscreen, noPadding && s.noPadding, className)}
      ref={dialogRef}
      onCancel={onClose}
    >
      <button className={s.button} onClick={onClose}>
        Close
      </button>
      {children}
    </dialog>
  )
}

export default Modal
