import { h, createContext } from 'preact'
import { useContext, useReducer } from 'preact/hooks'

const DropdownContext = createContext()

const Dropdown = ({ children, className }) => {
  const [isActive, toggle] = useReducer((value) => !value, false)

  return (
    <DropdownContext.Provider
      value={{
        toggle,
        isActive,
      }}
    >
      <div className={className}>{children}</div>
    </DropdownContext.Provider>
  )
}

const Toggle = ({ children, className }) => {
  const { toggle } = useContext(DropdownContext)

  return (
    <button onClick={toggle} className={className}>
      {children}
    </button>
  )
}

const Menu = ({ children, className }) => {
  const { isActive } = useContext(DropdownContext)

  return isActive && <div className={className}>{children}</div>
}

Dropdown.Toggle = Toggle
Dropdown.Menu = Menu

export default Dropdown
