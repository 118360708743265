export const SEARCH_SET = 'search: set'

export const fetchSearch = () => (_getState, _dispatch, rek) =>
  rek('/api/search').then((search) => {
    for (const prop in search) {
      if (Array.isArray(search[prop])) {
        search[prop].sort()
      }
    }

    return {
      search,
    }
  })
