import { h } from 'preact'
import { useCallback, useReducer, useState } from 'preact/hooks'
import cn from 'classnames'

import Modal from './modal.jsx'
import Slider from './slider.jsx'
import Spots from './spots.jsx'
import s from './image_gallery.module.scss'

const ImageGallery = ({ className, images, imageTitle }) => {
  const [currentImage, setCurrentImage] = useState({ selected: null, current: 0 })
  const [showFullscreen, toggleFullscreen] = useReducer(
    (visible, force) => (window.innerWidth < 640 ? false : typeof force === 'boolean' ? force : !visible),
    false,
  )

  const onTopSlide = useCallback(({ currentView }) => {
    setCurrentImage((currentState) => ({ current: currentView, selected: currentState.selected }))
  }, [])

  const onTopSlideEnd = useCallback(({ currentView }) => {
    setCurrentImage({ current: currentView, selected: null })
  }, [])

  const onBottomSlideClick = useCallback((e) => {
    e.preventDefault()
    // TODO just committing, fix this
    setCurrentImage((currentState) => {
      return { current: currentState.current, selected: e.currentTarget.dataset.index }
    })
  }, [])

  return (
    <section className={cn(s.base, className)}>
      <div className={s.topSliderContainer}>
        <Slider
          className={s.topSlider}
          currentSlide={currentImage.selected}
          onSlide={onTopSlide}
          onSlideEnd={onTopSlideEnd}
        >
          {images &&
            images.map((image, index) => (
              <figure
                key={image}
                className={cn(s.topSlide, index === currentImage && s.current)}
                onClick={toggleFullscreen}
              >
                <img src={image} title={imageTitle} />
              </figure>
            ))}
        </Slider>

        <Spots light current={currentImage.current} count={images.length} className={s.spots} />
      </div>

      <Slider className={s.bottomSlider} currentSlide={currentImage.selected ?? currentImage.current}>
        {images &&
          images.map((image, index) => (
            <figure
              key={image}
              data-index={index}
              className={cn(s.bottomSlide, index === (currentImage.selected ?? currentImage.current) && s.current)}
              onClick={onBottomSlideClick}
            >
              <img alt='' src={image} title='' />
            </figure>
          ))}
      </Slider>
      <Modal className={s.modal} open={showFullscreen} onClose={toggleFullscreen} noPadding>
        <Slider
          className={s.modalSlider}
          currentSlide={currentImage.selected}
          onSlide={onTopSlide}
          onSlideEnd={onTopSlideEnd}
        >
          {images &&
            images.map((image, index) => (
              <figure
                key={image}
                className={cn(s.modalSlide, index === currentImage && s.current)}
                onClick={toggleFullscreen}
              >
                <img src={image} title={imageTitle} />
              </figure>
            ))}
        </Slider>
      </Modal>
    </section>
  )
}

export default ImageGallery
