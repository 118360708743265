export default function loadScript(url) {
  return new Promise((resolve, reject) => {
    const element = document.createElement('script')

    element.src = url

    element.onload = resolve

    element.onerror = function (message, _source, _lineno, _colno, error) {
      reject(error)
    }

    document.body.appendChild(element)
  })
}
