import { h } from 'preact'

import error404 from '../images/error_404.svg'
import s from './error_page.module.scss'

const NotFoundPage = () => (
  <section className={s.base}>
    <div className={s.error404}>
      <img src={error404} />
      <div className={s.message}>
        <div>Hoppla!</div>
        <div>Det finns tyvärr inget att se här...</div>
      </div>
    </div>
  </section>
)

export default NotFoundPage
