export default function debounce(fnc, delay, leading) {
  let timer

  return (...args) => {
    if (leading && timer == null) fnc(...args)

    clearTimeout(timer)

    timer = setTimeout(() => {
      fnc(...args)
      timer = null
    }, delay)
  }
}
