import buttonFactory from '../../shared/components/button_factory.jsx'

// import deleteIcon from '../images/icon-delete.svg'
// import editIcon from '../images/icon-edit.svg'

import styles from './button.module.scss'

const icons = {
  // delete: deleteIcon,
  // edit: editIcon,
}

const defaults = {
  design: 'main',
  size: 'medium',
}

export default buttonFactory({ defaults, icons, styles })
