import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import cn from 'classnames'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { fetchRating } from '../store/reco_actions.js'

import s from './reco_mini.module.scss'

const RecoMini = ({ className }) => {
  const dispatch = useDispatch()
  const reco = useSelector('reco')

  useEffect(() => {
    if (!reco || !reco.rating) {
      dispatch(fetchRating())
    }
  }, [])

  return (
    reco &&
    reco.rating && (
      <section className={cn(s.base, className)}>
        <div className={s.logo} />
        <div className={s.icons}>
          {Array(5)
            .fill()
            .map((_, i) => {
              if (i + 1 <= reco.rating) {
                return (
                  <div className={cn(s.icon)}>
                    <div className={s.full} />
                  </div>
                )
                // return <div className={cn(s.icon, s.full)} />
              } else if (i + 1 === Math.ceil(reco.rating)) {
                return (
                  <div className={cn(s.icon, s.notEmpty)}>
                    <div className={s.full} style={{ width: (reco.rating % 1) * 100 + '%' }} />
                    <div className={s.empty} />
                  </div>
                )
              } else {
                // return <div className={cn(s.icon, s.empty)} />
                return (
                  <div className={cn(s.icon)}>
                    <div className={s.empty} />
                  </div>
                )
              }
            })}
        </div>

        <div className={s.text}>
          <span className={s.rating}>{reco.rating || '?'}</span>/5 från{' '}
          <span className={s.reviewCount}>{reco.reviewCount || '?'}</span> recensioner
        </div>
      </section>
    )
  )
}

export default RecoMini
