import { h } from 'preact'
import cn from 'classnames'

import SwishSvg from '../images/swish-dark.svg'
import { formatPrice } from '../util/format_number.js'

import s from './reserve_car_form.module.scss'

const ReserveCarForm = ({ car, className }) => (
  <form className={cn(s.base, className)}>
    <h1>Reservera {car.brand}</h1>
    <p>Du kan reservera denna bilen genom att swisha {formatPrice(5000)}</p>
    <p>
      <strong>Annan viktig text om hur det fungerar med att swisha och om hur de får pengarna tillbaka osv.</strong>
    </p>

    <a
      className={s.swishLink}
      href={`https://app.swish.nu/1/p/sw/?sw=1234406906&amt=1&cur=SEK&msg=${car.regNumber}&src=qr`}
    >
      <img src={SwishSvg} />
    </a>
    <img className={s.swishQr} src={`/api/swish?regNumber=${car.regNumber}`} />
  </form>
)

export default ReserveCarForm
