import { h } from 'preact'
import cn from 'classnames'

/**
 * @param {{
 *   base?: string
 *   text?: string
 *   button?: string
 *   noMargin?: string
 *   normal?: string
 *   error?: string
 *   success?: string
 * }} styles
 * @returns {import('preact').FunctionComponent<{
 *    dismiss?: () => void
 *    noMargin?: boolean
 *    type?: 'normal' | 'error' | 'success'
 * }>}
 */
export default function buttonFactory(styles) {
  const Message = ({ children, className, dismiss, noMargin, type = 'normal' }) => (
    <div className={cn(styles.base, styles[type], noMargin && styles.noMargin, className)}>
      <div className={styles.text}>{children}</div>

      {dismiss && (
        <button className={styles.button} onClick={() => dismiss()}>
          X
        </button>
      )}
    </div>
  )

  return Message
}
