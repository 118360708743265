export const fetchLatestCars = () => (_getState, _dispatch, rek) =>
  rek('/api/cars?limit=12&sort=-published_at').then(({ cars }) => ({ latestCars: cars }))

export const loadLatestCars = (cars) => () => ({
  type: 'latest cars: load',
  cars,
})

export const unloadLatestCars = () => () => ({
  type: 'latest cars: unload',
  latestCars: null,
})
