import { h, toChildArray } from 'preact'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import cn from 'classnames'

import debounce from '../util/debounce.js'
import throttle from '../util/throttle.js'

import s from './slider.module.scss'

const Slider = ({ children, className, currentSlide, onSlide, onSlideEnd }) => {
  const stateRef = useRef({ currentView: null, viewCount: null, slidesPerView: null })
  const slidesRef = useRef(null)
  const [state, setState] = useState({
    start: true,
    end: false,
  })

  useEffect(() => {
    if (currentSlide == null) return

    const { clientWidth } = slidesRef.current
    const slide = slidesRef.current.children[currentSlide]

    const left = Math.floor(slide.offsetLeft / clientWidth) * clientWidth

    slidesRef.current.scroll({ left, behavior: 'smooth' })
  }, [currentSlide])

  useEffect(() => {
    const resizeListener = debounce(setSize, 100)
    // TODO make sure 50 ms is enough to guarantee scroll end
    const scrollListener = debounce(() => {
      setScroll()
      onSlideEnd && onSlideEnd(stateRef.current)
    }, 50)

    window.addEventListener('resize', resizeListener)
    slidesRef.current.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
      slidesRef.current.removeEventListener('scroll', scrollListener)
    }
  }, [])

  useEffect(() => {
    if (onSlide) {
      const throttled = throttle(() => {
        const { clientWidth, scrollLeft } = slidesRef.current

        stateRef.current.currentView = Math.round(scrollLeft / clientWidth)

        onSlide(stateRef.current)
      }, 100)

      slidesRef.current.addEventListener('scroll', throttled)

      return () => slidesRef.current.removeEventListener('scroll', throttled)
    }
  }, [onSlide])

  useEffect(() => {
    setSize()
    setScroll()
  }, [toChildArray(children).length])

  const setSize = useCallback(() => {
    const { clientWidth, children, scrollLeft } = slidesRef.current

    const slidesPerView = children.length ? Math.round(clientWidth / children[0].clientWidth) : 0

    stateRef.current = {
      slidesPerView,
      viewCount: slidesPerView ? Math.ceil(children.length / slidesPerView) : 0,
      currentView: Math.round(scrollLeft / clientWidth),
    }
  }, [])

  const setScroll = useCallback(() => {
    setState((current) => {
      const { clientWidth, scrollLeft } = slidesRef.current

      stateRef.current.currentView = Math.round(scrollLeft / clientWidth)

      const start = stateRef.current.currentView === 0
      // // TODO figure out if the extra pixels can be solved some other way
      const end = stateRef.current.currentView === stateRef.current.viewCount - 1
      // const end = scrollLeft + clientWidth + 5 >= scrollWidth

      return start !== current.start || end !== current.end
        ? {
            ...current,
            start,
            end,
          }
        : current
    })
  }, [])

  const slide = useCallback((e) => {
    const { scrollLeft, clientWidth } = slidesRef.current
    const left = e.currentTarget.dataset.dir === 'prev' ? scrollLeft - clientWidth : scrollLeft + clientWidth

    slidesRef.current.scroll({ left, behavior: 'smooth' })
  }, [])

  return (
    <div className={cn(s.base, className)}>
      <button className={s.button} autocomplete='off' disabled={state.start} onClick={slide} data-dir='prev'>
        <span>Prev</span>
      </button>

      <div className={s.slides} ref={slidesRef}>
        {children}
      </div>

      <button className={s.button} autocomplete='off' disabled={state.end} onClick={slide} data-dir='next'>
        <span>Next</span>
      </button>
    </div>
  )
}

export default Slider
