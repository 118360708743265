import { h } from 'preact'
import cn from 'classnames'
import { formatPrice } from '../util/format_number.js'
import s from './car.module.scss'

import calculateMonthly from '../util/calculate_monthly.js'

export default ({
  basePath,
  brand,
  className,
  configuration,
  description,
  fuelType,
  id,
  images,
  mileage,
  model,
  modelDate,
  noSpecs,
  price,
  style,
}) => {
  return (
    <a href={`${basePath}/${id}`} style={style} className={cn(s.base, className, noSpecs && s.noSpecs)}>
      <figure className={s.imageContainer}>
        <img className={s.divumb} src={images[0]} alt={description} />
      </figure>

      <h1 className={s.model}>
        {brand} {model}
      </h1>
      <p className={s.configuration}>{configuration}</p>

      <div className={s.modelDate}>
        <div className={s.label}>
          <span>Modellår</span>
        </div>
        <div className={s.value}>
          <span>{modelDate}</span>
        </div>
      </div>

      <div className={s.mileage}>
        <div className={s.label}>
          <span>Mätarställning</span>
        </div>
        <div className={s.value}>
          <span>{mileage}&nbsp;mil</span>
        </div>
      </div>

      <div className={s.fuelType}>
        <div className={s.label}>
          <span>Drivmedel</span>
        </div>
        <div className={s.value}>
          <span>{fuelType ? fuelType.replace('Hybrid el', 'El') : 'N/A'}</span>
        </div>
      </div>

      <div className={s.priceMonthly}>{formatPrice(calculateMonthly(price))}/mån</div>
      <div className={s.priceFull}>{formatPrice(price)}</div>
    </a>
  )
}
