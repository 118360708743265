import { useSelector } from '@bmp/store/preact'

function format(value, variables) {
  if (!Array.isArray(variables)) {
    variables = [variables]
  }

  const split = value.split('%s')

  let result = ''

  for (let i = 0; i < split.length; i++) {
    result += split[i] + variables[i]
  }

  return result
}

function plural(value, count) {
  const pluralization = count === 1 ? 'one' : 'other'

  return (value[pluralization] || value.other).replace('%s', count)
}

function getValue(input) {
  const { locale, translations } = useSelector(['locale', 'translations'])

  const value = translations && (translations.global[input] || (translations.route && translations.route[input]))

  return value || (locale.default ? input : `UNKNOWN KEY "${input}"`)
}

export const useTranslate = () => {
  function translate(input, variables) {
    const value = getValue(input)

    if (typeof value === 'string') {
      return variables ? format(value, variables) : value
    } else {
      return plural(value, variables)
    }
  }

  return translate
}
