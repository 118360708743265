import cn from 'classnames'

export default function mergeStyles(styles) {
  const props = new Set()

  for (const style of styles) {
    for (const prop of Object.keys(style)) {
      props.add(prop)
    }
  }

  const obj = {}

  for (const prop of props) {
    obj[prop] = cn(styles.map((style) => style[prop]))
  }

  return obj
}
