import { h } from 'preact'
import cn from 'classnames'

import s from './logo.module.scss'

export default ({ className, children, href = '/', shield }) => (
  <a className={cn(s.base, shield && s.shield, !shield && s.name, className)} href={href}>
    <span>{children}</span>
  </a>
)
