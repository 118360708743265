import { h } from 'preact'
import cn from 'classnames'
import Input from './new_form/input.jsx'
import Select from './new_form/select.jsx'
import Button from './button.jsx'
import Textarea from './new_form/textarea.jsx'
import s from './workshop_form.module.scss'

const options = ['Verkstad', 'Service', 'Rekond']

const WorkshopForm = ({ className }) => {
  return (
    <form className={cn(s.base, className)}>
      <label className={s.label}>Vad behöver du hjälp med idag?</label>
      <Select design='noLabel' name='help' id='select' placeholder='Välj...' options={options} required />
      <h1>Kontaktuppgifter</h1>
      <Input className={s.name} name='name' label='Namn' placeholder='' required />
      <Input className={s.telephone} name='telephone' label='Telefon' placeholder='' required />
      <Input className={s.email} name='email' label='Email' placeholder='' required />
      <h1>Biluppgifter</h1>
      <Input className={s.regNumber} name='regNumber' label='Registreringsnummer' placeholder='' required />
      <Input className={s.odometer} name='odometer' label='Mätarställning' placeholder='' required />
      <Textarea className={s.message} name='message' label='Meddelande' placeholder='' />
      <Button>Skicka</Button>
    </form>
  )
}

export default WorkshopForm
