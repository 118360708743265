export default function hyphenate(string, length = 4) {
  if (length <= 0) {
    throw new Error('Length needs to be 1 or more!')
  }

  if (typeof string !== 'string') {
    return ''
  }

  if (string.includes('\u00AD')) {
    return string
  }

  return string.replace(/\w+/g, (word) => {
    const target = Math.ceil(word.length / length)

    let result = ''

    for (let i = 0; i < target; i++) {
      result += word.slice(i * length, i * length + length)

      if (i < target - 1) {
        result += '\u00AD'
      }
    }

    return result
  })
}
