// copied from https://github.com/azer/relative-date 2017-07-23

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const WEEK = 7 * DAY
const YEAR = DAY * 365
const MONTH = YEAR / 12

const formats = [
  [0.7 * MINUTE, 'precis nu'],
  [1.5 * MINUTE, 'en minut sen'],
  [60 * MINUTE, 'minuter sen', MINUTE],
  [1.5 * HOUR, 'en timme sen'],
  [DAY, 'timmar sen', HOUR],
  [2 * DAY, 'igår'],
  [7 * DAY, 'dagar sen', DAY],
  [1.5 * WEEK, 'en vecka sen'],
  [MONTH, 'veckor sen', WEEK],
  [1.5 * MONTH, 'en månad sen'],
  [YEAR, 'månader sen', MONTH],
  [1.5 * YEAR, 'ett år sen'],
  [Number.MAX_VALUE, 'år sen', YEAR],
]

export default function relativeDate(input, reference = null) {
  if (!reference) {
    reference = new Date().getTime()
  } else if (reference instanceof Date) {
    reference = reference.getTime()
  }

  if (input instanceof Date) {
    input = input.getTime()
  }

  const delta = reference - input

  let format
  let i
  let len

  for (i = -1, len = formats.length; ++i < len; ) {
    format = formats[i]

    if (delta < format[0]) {
      return format[2] == null ? format[1] : Math.round(delta / format[2]) + ' ' + format[1]
    }
  }
}
