import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'
import rek from 'rek'

import useRequestState from '../../shared/hooks/use_request_state.js'
import serializeForm from '../../shared/utils/serialize_form.js'

import Input from './new_form/input.jsx'
import Textarea from './new_form/textarea.jsx'
import Button from './button.jsx'
import Message from './message.jsx'

import s from './contact_form.module.scss'

const ContactForm = ({ className }) => {
  const [{ error, pending, success }, actions] = useRequestState()

  const onSubmit = useCallback((e) => {
    e.preventDefault()

    actions.pending()

    const form = e.currentTarget

    rek.post(form.action, serializeForm(e.currentTarget)).then(actions.success).catch(actions.error)
  }, [])

  return success ? (
    <Message type='success'>
      <strong>Tack för ditt mail!</strong>
      <p>Vi återkommer så snart som möjligt.</p>
    </Message>
  ) : (
    <form className={cn(s.base, className)} method='post' action='/api/send/contact' onSubmit={onSubmit}>
      {error && (
        <Message type='error' className={s.error} noMargin>
          <strong>Åh nej!</strong>
          <p>Något gick tyvärr fel, pröva gärna igen och kontakta oss om felet kvarstår.</p>
        </Message>
      )}
      <Input type='text' name='name' label='Namn' placeholder='' required />
      <Input type='email' name='email' label='Epost' placeholder='' required />
      <Textarea name='message' className={s.message} label='Meddelande' placeholder='' rows='8' required />

      <Button disabled={pending} className={s.button}>
        Skicka
      </Button>
    </form>
  )
}

export default ContactForm
