import { h } from 'preact'

import { useTranslate } from '../hooks/translate.js'
import FooterNavigation from './footer_navigation.jsx'
import s from './footer.module.scss'

const Footer = ({ routes, openingHours, organization }) => {
  const translate = useTranslate()

  return (
    <div className={s.base}>
      <div className={s.top}>
        <h2>{translate('Våra samarbetspartners')}</h2>
        <div className={s.logos}>
          <a className={s.bytbil} href='https://www.bytbil.com/handlare/carson-sverige-ab-6147' target='_blank'>
            <img src='/img/partner-bytbil.svg' alt='Bytbil' />
          </a>
          <a className={s.blocket} href='https://www.blocket.se/butik/carson' target='_blank'>
            <img src='/img/partner-blocket.svg' alt='Blocket' />
          </a>
          <a className={s.dnb} href='https://www.dnb.se/se/sv' target='_blank'>
            <img src='/img/partner-dnb.svg' alt='DnB' />
          </a>
        </div>
      </div>

      {organization && (
        <div className={s.bottom}>
          <section className={s.contact}>
            <h1>Kontakt</h1>
            <p className={s.address}>
              <span property='address' typeof='PostalAddress'>
                <strong>{organization.legalName}</strong>
                <br />
                <span property='streetAddress'>{organization.streetAddress}</span>
                <br />
                <span property='postalCode'>{organization.postalCode}</span>{' '}
                <span property='addressLocality'>{organization.locality}</span>
                <br />
                <span property='addressCountry'>{organization.country}</span>
              </span>
            </p>

            <a className={s.telephone} href={`tel:${organization.telephone.replace(/\D/g, '')}`} property='telephone'>
              {organization.telephone.replace(/ /g, '\xa0').replace(/-/g, '\u2011')}
            </a>

            <a className={s.email} href={`mailto:${organization.email}`} property='email'>
              {organization.email}
            </a>
          </section>

          <section className={s.openingHours}>
            <h1>Öppettider</h1>
            {openingHours && (
              <p className={s.openingHours}>
                <div className={s.day}>
                  <div className={s.label}>Idag</div>
                  <div className={s.value}>
                    {openingHours.today ? `${openingHours.today.opens} - ${openingHours.today.closes}` : 'Stängt'}
                  </div>
                </div>
                <div className={s.day}>
                  <div className={s.label}>Imorgon</div>
                  <div className={s.value}>
                    {openingHours.tomorrow
                      ? `${openingHours.tomorrow.opens} - ${openingHours.tomorrow.closes}`
                      : 'Stängt'}
                  </div>
                </div>
              </p>
            )}
          </section>

          <section className={s.links}>
            <h1>{translate('Genvägar')}</h1>

            <FooterNavigation routes={routes} />
          </section>

          <section className={s.social}>
            <h1>{translate('Följ oss')}</h1>
            <div className={s.icons}>
              <a className={s.facebook} href='https://www.facebook.com/Carson.nu'>
                <span>LinkedIn</span>
              </a>
              <a className={s.instagram} href='https://www.instagram.com/carson.nu/'>
                <span>Facebook</span>
              </a>
              <a className={s.tiktok} href='https://www.tiktok.com/@carsonsverige'>
                <span>Tiktok</span>
              </a>
              <a className={s.linkedin} href='https://www.linkedin.com/company/carson-sverige-ab/'>
                <span>LinkedIn</span>
              </a>
            </div>
          </section>
        </div>
      )}
    </div>
  )
}
export default Footer
