// copied and modified from https://github.com/sindresorhus/throttleit/blob/ed1d22c70a964ef0299d0400dbfd1fbedef56a59/index.js

export default function throttle(fnc, wait) {
  let timeout
  let lastCallTime = 0

  return function throttled(...args) {
    clearTimeout(timeout)

    const now = Date.now()
    const timeSinceLastCall = now - lastCallTime
    const delayForNextCall = wait - timeSinceLastCall

    if (delayForNextCall <= 0) {
      lastCallTime = now
      fnc(...args)
    } else {
      timeout = setTimeout(() => {
        lastCallTime = Date.now()
        fnc(...args)
      }, delayForNextCall)
    }
  }
}
