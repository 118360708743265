export default {
  segeltorp: {
    address: {
      street: 'Smista Allé 15',
      postalCode: '141 74',
      locality: 'Segeltorp',
    },
    telephone: '08 - 522 927 00',
    latitude: 59.2809703,
    longitude: 17.9234934,
  },
  vallentuna: {
    address: {
      street: 'Fågelsångsvägen 8',
      postalCode: '186 42',
      locality: 'Vallentuna',
    },
    telephone: '08 - 522 927 00',
    latitude: 59.5152709,
    longitude: 18.0933787,
  },
}
