import { h } from 'preact'
import cn from 'classnames'
import { useSelector } from '@bmp/store/preact'

import Head from './head.jsx'
import { useContent } from '../hooks/content.js'

import s from './content_main_page.module.scss'
import sLayout from './layout.module.scss'

const ContentMainPage = () => {
  const content = useContent()
  const { route } = useSelector('location')

  return (
    <section className={cn(sLayout.page, s.base)}>
      <Head>
        <title> : {route.title}</title>
      </Head>

      <header className={s.header}>
        <h1>{route.title}</h1>
      </header>
      <section className={s.content} dangerouslySetInnerHTML={{ __html: content('main') }} />
    </section>
  )
}

export default ContentMainPage
