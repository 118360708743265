import { h } from 'preact'
import { useCallback, useReducer, useRef } from 'preact/hooks'
import { CSSTransition } from 'preact-transitioning'
import cn from 'classnames'
import { useLocation } from 'preact-iso'
import { omit, isEmpty } from 'lowline'

import qs from 'mini-qs'

import serializeForm from '../../shared/utils/serialize_form.js'

import CarIcon from '../images/icon-car.svg'
import CrossIcon from '../images/icon-cross.svg'
import EyeOffIcon from '../images/icon-eye-off.svg'
import FuelTypeIcon from '../images/icon-fuel-type.svg'
import FilterIcon from '../images/icon-filter.svg'
import GearboxIcon from '../images/icon-gearbox.svg'
import SearchIcon from '../images/icon-search.svg'
import SortIcon from '../images/icon-sort.svg'

import Button from './button.jsx'
import Checkbox from './new_form/checkbox.jsx'
import Input from './new_form/input.jsx'
import DoubleRange from './new_form/double_range.jsx'
import Select from './new_form/select.jsx'

import s from './car_search_form.module.scss'

const equipments = [
  { label: 'Svensksåld', value: 'svensksåld' },
  { label: 'Fyrhjulsdrift', value: '4WD' },
  { label: 'Dragkrok', value: 'dragkrok' },
]

const endListener = (node, done) => node.addEventListener('transitionend', done, { once: true, capture: false })

const CarSearchForm = ({ className, location, search }) => {
  const { route } = useLocation()
  const formRef = useRef(null)
  const [showFilters, toggleFilters] = useReducer((current) => !current, false)
  const onSubmit = useCallback((e) => {
    e.preventDefault()

    const data = serializeForm(e.target.form || e.target)

    const search = !isEmpty(data) ? '?' + qs.stringify(data) : ''

    route(`/bilar${search}`)
  }, [])

  const clearFilters = useCallback(() => {
    route('/bilar')

    setTimeout(() => {
      formRef.current.reset()
    }, 200)
  }, [])

  if (!search) {
    return null
  }

  const query = (location.search && omit(qs.parse(location.search.slice(1)), ['offset'])) || {}

  if (query.equipment && !Array.isArray(query.equipment)) {
    query.equipment = [query.equipment]
  }

  return (
    <form className={cn(s.base, className)} onSubmit={onSubmit} values={query} initialValues={query} ref={formRef}>
      <div className={s.top}>
        <Input
          className={s.search}
          design='centerMobile'
          name='search'
          label='Sök på modell, utrustning mm'
          placeholder=''
          icon={`url(${SearchIcon})`}
          defaultValue={query.search}
          showValidity={false}
        />
        <Button className={s.searchButton}>Sök bilar</Button>
        <Select
          className={s.sort}
          size='small'
          name='sort'
          noEmpty
          icon={`url(${SortIcon})`}
          label='Sortera'
          defaultValue={query.sort}
          onChange={onSubmit}
          options={[
            { label: 'Senast inlagd', value: '' },
            { label: 'Modell', value: 'model' },
            { label: 'Pris, fallande', value: '-price' },
            { label: 'Pris, stigande', value: 'price' },
            { label: 'Modellår, fallande', value: '-modelDate' },
            { label: 'Modellår, stigande', value: 'modelDate' },
          ]}
        />
        {location.search && (
          <Button
            type='button'
            color='black'
            invert
            size='small'
            icon={CrossIcon}
            iconSize='10px'
            className={s.clearFilterButton}
            onClick={clearFilters}
          >
            Rensa filter
          </Button>
        )}
        <Button
          type='button'
          invert
          size='small'
          className={s.filterButton}
          icon={showFilters ? EyeOffIcon : FilterIcon}
          onClick={toggleFilters}
        >
          {showFilters ? 'Dölj filter' : 'Filter'}
        </Button>
      </div>

      <CSSTransition
        alwaysMounted
        onEnter={(node) => (node.style.height = `${node.scrollHeight}px`)}
        onExit={(node) => (node.style.height = '0px')}
        in={showFilters}
        addEndListener={endListener}
        classNames={s}
      >
        <div className={cn(s.bottom)}>
          <Select
            design='labelOnTop'
            className={s.brand}
            name='brand'
            label='Märke'
            placeholder='Alla'
            icon={`url(${CarIcon})`}
            options={search.brand}
            onChange={onSubmit}
            defaultValue={query.brand}
          />
          <Select
            className={s.transmission}
            design='labelOnTop'
            name='transmission'
            label='Växellåda'
            placeholder='Alla'
            icon={`url(${GearboxIcon})`}
            options={search.transmission}
            defaultValue={query.transmission}
            onChange={onSubmit}
          />
          <Select
            className={s.fuelType}
            design='labelOnTop'
            name='fuelType'
            label='Drivmedel'
            placeholder='Alla'
            icon={`url(${FuelTypeIcon})`}
            options={search.fuelType}
            defaultValue={query.fuelType}
            onChange={onSubmit}
          />
          <Select
            className={s.bodyType}
            design='labelOnTop'
            name='bodyType'
            label='Fordonstyp'
            placeholder='Alla'
            icon={`url(${CarIcon})`}
            options={search.bodyType}
            defaultValue={query.bodyType}
            onChange={onSubmit}
          />

          <DoubleRange
            className={cn(s.doubleRange, s.price)}
            format='price'
            nameLower='priceFrom'
            nameUpper='priceTo'
            label='Pris'
            min={search.price.min}
            max={search.price.max}
            step={50000}
            defaultLower={query.priceFrom}
            defaultUpper={query.priceTo}
            onChange={onSubmit}
          />

          <DoubleRange
            className={cn(s.doubleRange, s.modelDate)}
            nameLower='modelDateFrom'
            nameUpper='modelDateTo'
            label='Modellår'
            min={search.modelDate.min}
            max={search.modelDate.max}
            step={1}
            defaultLower={query.modelDateFrom}
            defaultUpper={query.modelDateTo}
            onChange={onSubmit}
          />

          <DoubleRange
            className={cn(s.doubleRange, s.mileage)}
            format='number'
            nameLower='mileageFrom'
            nameUpper='mileageTo'
            label='Miltal'
            min={search.mileage.min}
            max={search.mileage.max}
            step={1000}
            defaultLower={query.mileageFrom}
            defaultUpper={query.mileageTo}
            onChange={onSubmit}
          />

          <div className={s.equipment}>
            <label className={s.label}>Övrigt</label>
            <div className={s.checkboxes}>
              {equipments.map(({ label, value }) => (
                <Checkbox
                  name='equipment'
                  label={label}
                  value={value}
                  defaultChecked={query.equipment && query.equipment.includes(value)}
                  style='button'
                  onChange={onSubmit}
                />
              ))}
              <Checkbox
                name='leasable'
                label='Leasbar'
                defaultChecked={query.leasable}
                style='button'
                onChange={onSubmit}
              />
            </div>
          </div>
          <div className={s.mobileButtons}>
            {location.search && (
              <Button
                type='button'
                color='black'
                invert
                size='small'
                icon={CrossIcon}
                iconSize='10px'
                className={s.mobileClearFilterButton}
                onClick={clearFilters}
              >
                Rensa filter
              </Button>
            )}
            <Button
              type='button'
              invert
              size='small'
              className={s.filterButton}
              icon={showFilters ? EyeOffIcon : FilterIcon}
              onClick={toggleFilters}
            >
              {showFilters ? 'Dölj filter' : 'Filter'}
            </Button>
          </div>
        </div>
      </CSSTransition>
    </form>
  )
}

export default CarSearchForm
