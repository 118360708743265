import { h } from 'preact'
import cn from 'classnames'

import { useTranslate } from '../hooks/translate.js'
import Button from './button.jsx'
import Reco from './reco.jsx'
import Logo from './logo.jsx'
import LatestCars from './latest_cars.jsx'
import FrontSearchForm from './front_search_form.jsx'
import RecoLogo from '../images/reco-logo.svg'

import s from './start_page.module.scss'
import sLayout from './layout.module.scss'

const StartPage = () => {
  const translate = useTranslate()

  return (
    <section className={cn(sLayout.page, s.base)}>
      <header className={s.heroWrapper}>
        <div className={s.hero}>
          <Logo className={s.logo} />
          <h1>Vi underlättar din bilvardag</h1>
          <p>
            Välkommen till oss på Carson. Hos oss kan du tryggt både köpa bil och sälja bil. Vi är bäst i Sverige på det
            vi gör.
          </p>

          <Button href='/bilar' className={cn(s.link, s.bilar)} fullWidth>
            Hitta din bil
          </Button>
          <Button href='/salj-din-bil' className={cn(s.link, s.salj)} invert color='white' fullWidth>
            Sälj din bil
          </Button>
        </div>
      </header>
      <section className={s.openingHours}>
        <h1>Öppettider</h1>
        <div className={s.openingHour}>
          <div className={s.days}>Mån&nbsp;-&nbsp; Fre</div>
          <div className={s.hours}>10:00&nbsp;-&nbsp;18:00</div>
        </div>
        <div className={s.openingHour}>
          <div className={s.days}>Lör&nbsp;&#8209;&nbsp;Sön</div>
          <div className={s.hours}>10:00&nbsp;-&nbsp;15:00</div>
        </div>
      </section>

      <div className={cn(s.section, s.search)}>
        <h1>Återkommer med headline</h1>
        <FrontSearchForm className={s.form} />
      </div>

      <section className={cn(s.section, s.latestCars)}>
        <h1>{translate('Senast inkomna bilar')}</h1>
        <LatestCars className={s.latestCarsComponent} />
        <a href='/bilar'>Visa alla</a>
      </section>

      <section className={cn(s.section, s.video)}>
        <h1>Vi är det självklara valet för din trygga bilaffär – Här är varför</h1>

        <div className={s.videoWrapper}>
          <video controls>
            <source src='/video/carson2.mp4' type='video/mp4' />
          </video>
        </div>
      </section>

      <div className={s.recoWrapper}>
        <section className={s.reco}>
          <h1>Så tycker våra kunder om oss!</h1>
          <img className={s.logo} src={RecoLogo} />
          <Reco className={s.component} />
        </section>
      </div>

      <div className={s.workshopWrapper}>
        <div className={s.workshop}>
          <h1>Vi har vår egen verkstad</h1>

          <p>
            Hos oss på Carson så erbjuder vi att även serva och ta hand om din befintliga bil. Det gör vi i vår egen
            verkstad och rekond. Vi erbjuder även fri lånebil och låga priser, samt att vi har mycket kompententa
            servicetekniker Anton och Marcus som bryr sig om din bil!
          </p>

          <Button href='/verkstad' color='orange' className={s.button}>
            Läs mer
          </Button>
        </div>
      </div>

      <section className={cn(s.section, s.services)}>
        <h1>Fler av våra tjänster</h1>
        <p>Vi tillhandahåller flertal tjänster runt om själva bilhandlandet.</p>

        <section className={s.cards}>
          <section className={cn(s.card, s.förmedling)}>
            <h1>Förmedling & köp</h1>
            <p>Trygg bilförmedling och snabb betalning vid försäljning eller köp hos Carson</p>
          </section>

          <section className={cn(s.card, s.försäljning)}>
            <h1>Försäljning & inbyte</h1>
            <p>Carson erbjuder ett brett utbud av testade bilar och tar din bil i inbyte med snabb värdering.</p>
          </section>

          <section className={cn(s.card, s.finansiering)}>
            <h1>Finansiering</h1>
            <p>Vi hjälper dig hitta en passande finansieringslösning, inklusive företagsleasing från 0 kr kontant.</p>
          </section>

          <section className={cn(s.card, s.försäkring)}>
            <h1>Försäkring</h1>
            <p>Vi hjälper dig att försäkra din bil med de bästa försäkringslösningarna från flera samarbetspartners.</p>
          </section>
        </section>
        <a href='/tjanster'>Läs mer</a>
      </section>
    </section>
  )
}

export default StartPage
