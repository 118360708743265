export default function (el) {
  const appElement = document.getElementById('app')
  // const top = parseInt(getComputedStyle(document.body).paddingTop, 10)
  const top = parseInt(getComputedStyle(appElement).paddingTop, 10)

  let ref = el
  let actualOffset = 0

  while (ref) {
    actualOffset += ref.offsetTop
    ref = ref.offsetParent
  }

  const offset = actualOffset - top - 15
  // const offset = actualOffset

  if (window.pageYOffset > offset) {
    window.scrollTo(0, offset)
  }
}
