import { h } from 'preact'
import cn from 'classnames'
import Input from './new_form/input.jsx'
import Button from './button.jsx'
import s from './sell_your_car_form.module.scss'

const SellYourCarForm = ({ className }) => {
  return (
    <form className={cn(s.base, className)}>
      <Input className={s.name} name='name' label='Namn' placeholder='' required />
      <Input className={s.telephone} name='telephone' label='Telefon' placeholder='' required />
      <Input className={s.email} name='email' label='Email' placeholder='' required />
      <Input className={s.regNumber} name='regNumber' label='Registreringsnummer' placeholder='' required />
      <Button>Skicka</Button>
    </form>
  )
}

export default SellYourCarForm
