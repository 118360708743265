const scrollingElement = typeof window !== 'undefined' && (document.scrollingElement || document.documentElement)

let scrollTop

export default {
  disable() {
    scrollTop = (document.scrollingElement || document.documentElement).scrollTop

    document.body.style.top = `-${scrollTop}px`
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
  },

  enable() {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.width = ''
    scrollingElement.scrollTop = scrollTop
  },
}
