import { h, Component } from 'preact'
import cn from 'classnames'
import s from './spots.module.scss'

// pixels per millisecond
const SPEED = 0.01

export default class Spots extends Component {
  animationID = null

  prevTime = null

  state = {
    index: 0,
  }

  componentDidUpdate(prevProps) {
    const { current } = this.props
    if (current !== prevProps.current) {
      this.goTo(current)
    }
  }

  animationStep(target) {
    if (this.animationID != null) {
      return
    }

    this.animationID = window.requestAnimationFrame(() => {
      // in chrome 71 the first timestamp passed into this rAF callback was
      // always earlier than prevTime generated in startAnimation. using
      // performance.now instead of the callback argument works consistently
      const timestamp = performance.now()

      const deltaTime = timestamp - this.prevTime
      this.prevTime = timestamp

      const { index } = this.state

      const forward = target - index > 0

      const delta = deltaTime * SPEED
      const nextIndex = forward ? index + delta : index - delta
      const newIndex = (forward && nextIndex > target) || (!forward && nextIndex < target) ? target : nextIndex

      this.setState({
        index: newIndex,
      })

      this.animationID = null

      if (newIndex !== target) {
        this.animationStep(target)
      }
    })
  }

  startAnimation(target) {
    this.prevTime = performance.now()

    this.animationStep(target)
  }

  stopAnimation() {
    if (this.animationID) {
      window.cancelAnimationFrame(this.animationID)
      this.animationID = null
    }
  }

  goTo(target) {
    const { count } = this.props

    this.stopAnimation()

    if (target < 0) {
      target = 0
    } else if (target >= count) {
      target = count - 1
    }

    this.startAnimation(target)
  }

  getStyle(itemIndex, currentIndex) {
    const offset = itemIndex - currentIndex
    const abs = Math.abs(offset)

    if (abs > 4) {
      return {
        display: 'none',
      }
    }

    const rad = ((offset + 4) / 8) * Math.PI

    const size = Math.sin(rad) * 20
    const left = -Math.cos(rad) * 70 - size / 2
    const top = (20 - size) / 2

    return {
      left: `${left}px`,
      top: `${top}px`,
      width: `${size}px`,
      height: `${size}px`,
    }
  }

  render() {
    const { className, count, current, light } = this.props
    const { index } = this.state

    return (
      <div className={cn(s.base, light && s.light, className)}>
        {count !== Infinity &&
          Array(count)
            .fill(1)
            .map((_, i) => (
              <div key={i} style={this.getStyle(i, index)} className={cn(s.spot, current === i && s.current)}>
                <span>{i}</span>
              </div>
            ))}
      </div>
    )
  }
}
