import { h } from 'preact'
import cn from 'classnames'

export default function buttonFactory({ defaults, icons, styles }) {
  const Button = ({
    autoHeight = defaults?.autoHeight,
    children,
    className,
    color = defaults?.color,
    design = defaults?.design,
    disabled,
    href,
    fullWidth = defaults?.fullWidth,
    icon = defaults?.icon,
    iconSize,
    invert = defaults?.invert,
    onClick,
    size = defaults?.size,
    tabIndex,
    tag: Tag = href ? 'a' : 'button',
    title,
    type,
  }) => (
    <Tag
      className={cn(
        styles.base,
        design && styles[design],
        size && styles[size],
        color && styles[color],
        autoHeight && styles.autoHeight,
        fullWidth && styles.fullWidth,
        invert && styles.invert,
        className,
      )}
      style={iconSize && { '--icon-size': iconSize }}
      disabled={disabled}
      href={href}
      onClick={onClick}
      tabIndex={tabIndex}
      title={title}
      type={type}
    >
      {icon && <i className={styles.icon} style={{ maskImage: `url(${icons[icon] || icon})` }} />}
      {children && <span>{children}</span>}
    </Tag>
  )

  return Button
}
