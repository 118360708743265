const loan = {
  fee: 55,
  rate: 0.0495,
}

export default function calculateMonthly(price, { cash = price * 0.2, time = 36, rest = price * 0.5 } = {}) {
  const actualPrice = price - rest - cash
  const r = loan.rate / 12

  return Math.round((r + r / (Math.pow(1 + r, time) - 1)) * actualPrice + rest * r)
}
