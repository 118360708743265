import { h } from 'preact'
import { useDispatch, useSelector } from '@bmp/store/preact'
import cn from 'classnames'

import { fetchLatestCars } from '../store/latest_cars_actions.js'

import Car from './car.jsx'
import Slider from './slider.jsx'
import s from './latest_cars.module.scss'

const LatestCars = ({ className }) => {
  const dispatch = useDispatch()

  const { latestCars, locale } = useSelector(['latestCars', 'locale'])

  if (!latestCars) {
    throw dispatch(fetchLatestCars())
  }

  return (
    <Slider className={cn(s.slider, className)}>
      {latestCars &&
        latestCars.map((car) => <Car {...car} basePath={locale.default ? '/bilar' : '/cars'} className={s.slide} />)}
    </Slider>
  )
}

export default LatestCars
