import { h } from 'preact'
import cn from 'classnames'

import relativeDate from '../util/relative_date.js'
import s from './reco_review.module.scss'

const RecoReview = ({ className, review, style, maxLength = 300 }) => {
  const text = review.text.length > maxLength ? review.text.slice(0, maxLength) + '...' : review.text

  return (
    <article className={cn(s.base, className)} style={style}>
      <a href={`https://www.reco.se/r/${review.id}`} target='blank'>
        <ul className={cn(s.stars, s[`rating-${review.rating}`])}>
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
        <blockquote className={s.quote}>{text.trim()}</blockquote>
        <div className={s.author}>{(review.reviewer && review.reviewer.screenName) || 'Anonym'}</div>
        <time>{relativeDate(review.created)}</time>
      </a>
    </article>
  )
}

export default RecoReview
