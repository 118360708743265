import { h } from 'preact'
import { useCallback, useState } from 'preact/hooks'
import cn from 'classnames'

import { formatNumber, formatPrice } from '../../util/format_number.js'

import s from './double_range.module.scss'

const formats = {
  number: formatNumber,
  price: formatPrice,
}

const Range = ({
  className,
  label,
  format,
  step = 100,
  min,
  max,
  nameLower,
  nameUpper,
  defaultLower,
  defaultUpper,
  onChange,
}) => {
  min = Math.floor(min / step) * step
  max = Math.ceil(max / step) * step

  const [localLower, setLocalLower] = useState(defaultLower == null ? min : +defaultLower)
  const [localUpper, setLocalUpper] = useState(defaultUpper == null ? max : +defaultUpper)

  const lowerMax = Math.floor((localLower + (localUpper - localLower) / 2) / step) * step
  const upperMin = lowerMax === localLower ? localUpper : lowerMax

  const onInput = useCallback((e) => {
    ;(e.target.dataset.lower ? setLocalLower : setLocalUpper)(e.target.valueAsNumber)
  }, [])

  return (
    <div
      className={cn(s.base, className)}
      style={{
        '--lower-width': `${((lowerMax - min) / (max - min)) * 100}%`,
        '--upper-width': `${((max - upperMin) / (max - min)) * 100}%`,
      }}
    >
      {label && <label className={s.label}>{label}</label>}

      <div className={s.wrapper}>
        <div className={s.track}>
          <input
            type='range'
            name={nameLower}
            className={cn(s.element, s.lower)}
            min={min}
            max={lowerMax}
            step={step}
            defaultValue={localLower}
            onInput={onInput}
            onChange={onChange}
            data-lower
            autocomplete='off'
          />
          <input
            type='range'
            name={nameUpper}
            className={cn(s.element, s.upper)}
            min={upperMin}
            max={max}
            step={step}
            defaultValue={localUpper}
            onInput={onInput}
            onChange={onChange}
            autocomplete='off'
          />
          <div
            className={s.progress}
            style={{
              left: ((localLower - min) / (max - min)) * 100 + '%',
              right: ((max - localUpper) / (max - min)) * 100 + '%',
            }}
          />
        </div>

        <div className={s.values}>
          <div className={cn(s.lower, localLower !== min && s.selected)}>
            {format ? formats[format](localLower) : localLower}
          </div>
          <div className={cn(s.upper, localUpper !== max && s.selected)}>
            {format ? formats[format](localUpper) : localUpper}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Range
