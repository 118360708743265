import { useState } from 'preact/hooks'
import isLoading from '../utils/is_loading.js'

const useRequestState = () => {
  const initialState = {
    error: null,
    pending: false,
    success: false,
    response: null,
  }

  const [state, setState] = useState(initialState)

  const actions = {
    reset() {
      isLoading(false)

      setState(initialState)
    },

    error(error) {
      isLoading(false)

      setState({
        pending: false,
        success: false,
        error,
        response: null,
      })
    },

    pending() {
      isLoading(true)

      setState({
        pending: true,
        success: false,
        error: null,
        response: null,
      })
    },

    success(response = null) {
      isLoading(false)

      setState({
        pending: false,
        success: true,
        error: null,
        response,
      })
    },
  }

  return [state, actions]
}

export default useRequestState
