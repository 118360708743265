import { h, createRef, Component } from 'preact'
import cn from 'classnames'

import loadScript from '../util/load_script.js'

import s from './google_maps.module.scss'

let scriptLoading

export default class GoogleMaps extends Component {
  canvasRef = createRef()

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined' && !scriptLoading) {
      scriptLoading = loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBquvsV7QJIiFBrgGpuHMbti1W6L1imqoc')
    }
  }

  componentDidMount() {
    scriptLoading.then(() => {
      const mapCanvas = this.canvasRef.current
      const pos = new google.maps.LatLng(this.props.latitude, this.props.longitude)
      const mapOptions = {
        scrollwheel: false,
        draggable: true,
        mapTypeControl: false,
        streetViewControl: false,
        panControl: false,
        center: pos,
        zoom: 11,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }

      const map = new google.maps.Map(mapCanvas, mapOptions)

      // eslint-disable-next-line no-new
      new google.maps.Marker({
        position: pos,
        map,
        title: 'Carson',
      })
    })
  }

  render() {
    const { children } = this.props

    return (
      <div className={cn(s.container, this.props.className)}>
        <div className={s.canvas} id='map-canvas' ref={this.canvasRef} />
        {children}
      </div>
    )
  }
}
