export const fetchOpeningHours = () => (_getState, _dispatch, rek) =>
  // rek('/api/opening-hours').then((openingHours) => {
  //   console.log(openingHours)
  //   return {
  //     openingHours,
  //   }
  // })
  rek('/api/opening-hours').then(({ regular, special, days }) => {
    const daysOfWeek = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']

    // TODO test how this handles timezones
    const todaysDate = new Date()
    const tomorrowsDate = new Date()
    tomorrowsDate.setDate(todaysDate.getDate() + 1)
    const todaysDayOfWeek = daysOfWeek[todaysDate.getDay()]
    const tomorrowsDayOfWeek = daysOfWeek[tomorrowsDate.getDay()]

    const specialToday = days && days.find((day) => day.date.slice(0, 10) === todaysDate.toISOString().slice(0, 10))
    const specialTomorrow =
      days && days.find((day) => day.date.slice(0, 10) === tomorrowsDate.toISOString().slice(0, 10))

    return {
      openingHours: {
        today: specialToday
          ? specialToday.hours
          : (special && new Date(special.validFrom) <= todaysDate ? special : regular).dayOfWeek[todaysDayOfWeek],
        tomorrow: specialTomorrow
          ? specialTomorrow.hours
          : (special && new Date(special.validFrom) <= tomorrowsDate ? special : regular).dayOfWeek[tomorrowsDayOfWeek],
        regular,
        special,
        days,
      },
    }
  })

export const unloadOpeningHours = () => () => ({
  openingHours: null,
})

// export const convert = (state) => {
//           <div>Hours: Mo-Fr 10am-7pm Sa 10am-22pm Su 10am-21pm
//                 <meta property="openingHours" content="Mo-Fr 10:00-18:00"/>
//                 <meta property="openingHours" content="Sa 10:00-15:00"/>
//                 <meta property="openingHours" content="Closed"/>
//         </div>
//   kj

// }
